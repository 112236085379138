import { Box,  CircularProgress, Container,  Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import tchLogo from '../assets/tch-logo-v.png';
import axios from "axios";

const ProcessPlayer = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const forceVerification = urlParams.get("force_verification") === "true";

  const location = useLocation();
  const navigate = useNavigate();
  const {
    userid,
    email
  } = location.state || {};

  useEffect(() => {
    if (email) {
      fetchUserData(email);
    } else {
      setTimeout(() => navigate('/tch/notfound'), 3000);
    }
  }, [email, navigate]);

  const fetchUserData = async () => {
    try {
      const searchparams = {
        email: email
      }
  
      const response = await axios.post(`${process.env.REACT_APP_DATASYNC_URL}/descope/search`, searchparams);
      const data = response.data;
  
      if (data) {
        const idVerified = data.customAttributes?.idVerified;
        if (forceVerification || !idVerified || (idVerified !== "APPROVED" && idVerified !== "SUSPECTED")) {
          try {
            const redirectUrl = await handleAPICall(userid, email);
            console.log("redirectUrl: ", redirectUrl);
            setTimeout(() => {
              window.location.replace(redirectUrl);
            }, 3000);
          } catch (error) {
            console.error(error.message);
          }
        } else {
          navigate(`/tch/checking?email=${encodeURIComponent(email)}`);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/tch/error', {
        state: {
          message: 'There was a problem retrieving your information, proceed to the front desk for assistance.'
        }
      });
    }
  }

  return (
    <>
      <div className='page'>
        <Container maxWidth="sm" sx={{paddingY: '16px'}}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ width: "300px", marginBottom: "1rem" }}
                    src={tchLogo}
                    alt="TCH Logo"
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem', marginTop: '2rem' }}>
                    <CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[50],
                      }}
                      thickness={3}
                      size={60}
                    />
                  </Box>
                  <Typography mb={3}>
                    Your account is not verified. Please prepare your driver's license for ID verification. Redirecting...
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const buildUrlWithParams = (baseUrl, params) => {
  const queryString = new URLSearchParams(params).toString();
  return `${baseUrl}?${queryString}`;
};

const handleAPICall = async (guid, email) => {
  const baseUrl = `${process.env.REACT_APP_AUTH_URL}/tch/checking`;
  const params = {
    email: encodeURIComponent(email),
  };

  const successurl = buildUrlWithParams(baseUrl, params);
  const errorurl = buildUrlWithParams(baseUrl, params);

  let formData = new FormData(); 
  formData.append('GUID', guid); 
  formData.append('email', email);
  formData.append('successUrl', successurl);
  formData.append('errorUrl', errorurl);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKOFFICE_URL}/api/identity/pokeratlas/initiate`,
      {
        method: "POST",
        headers: {
          Accept: "*/*"
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data.web?.href || null;
  } catch (error) {
    console.error('Fetch error:', error);
    return null;
  }
}

export default ProcessPlayer;
