import axios from 'axios';


const handleAPICall = async (guid, loginid, login_type) => {
    const baseUrl = `https://auth.${process.env.REACT_APP_DOMAIN}/verification-complete`;
    const params = {
      email: encodeURIComponent(loginid),
    };
  
    const successurl = buildUrlWithParams(baseUrl, params,login_type);
    const errorurl = buildUrlWithParams(baseUrl, params, login_type);
    const unverifiedurl = buildUrlWithParams(baseUrl, params, login_type);
  
    let formData = new FormData(); 
    formData.append('GUID', guid); 
    formData.append('email', loginid); 
    formData.append('successUrl', successurl);
    formData.append('errorUrl', errorurl);
  
    try {
      const response = await fetch(
        `https://backoffice.${process.env.REACT_APP_DOMAIN}/api/identity/descope/initiate`,
        {
          method: "POST",
          headers: {
            Accept: "*/*"
          },
          body: formData,
        }
      );
      const data = await response.json();
      return data.web?.href || null;
    } catch (error) {
      console.error('Fetch error:', error);
      return null;
    }
  }
  
  const buildUrlWithParams = (baseUrl, params, login_type) => {
    const queryString = new URLSearchParams(params).toString();
    const loginTypeParam = login_type ? `&type=${login_type}` : '';
    return `${baseUrl}?${queryString}${loginTypeParam}`;
  };


const processJumioService = async (userid, email, login_type) => {
  try {
    const redirectUrl = await handleAPICall(userid, email,login_type);
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 3000);
  } catch (error) {
    console.error(error.message);
  }
};

export { handleAPICall, processJumioService };
