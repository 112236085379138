import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import logo from '../../assets/hijack-poker-with-logo.png';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function getLocalTime(timestamp) {
  const date = new Date(timestamp * 1000);
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  const formattedTime = `${date.getHours() % 12 || 12}:${String(date.getMinutes()).padStart(2, '0')} ${ampm}`;
  return `${formattedDate} ${formattedTime}`;
}

export default function Maintenance({
  title,
  timestamp 
}) {
  const formattedTime = timestamp ? getLocalTime(timestamp) : "N/A";

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingBottom: '10%',
    }}>
      <Container maxWidth="xs" style={{
        backgroundColor: '#1e1e1e',
        padding: '40px 20px',
        borderRadius: '12px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
        textAlign: 'center',
      }}>
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
          
          <Grid item>
            <img 
              src={logo} 
              alt="Hijack Poker Logo" 
              style={{
                width: '280px',
                height: 'auto',
                marginBottom: '10px',
              }} 
            />
          </Grid>

          <Grid item>
            <Typography variant="h6" style={{ color: '#ffffff', marginTop: '0.5rem' }}>
              {title}
            </Typography>
          </Grid>

          <Grid item>
            <ConstructionIcon style={{ fontSize: '80px', color: '#ffffff', marginTop: '15px' }} />
          </Grid>

          <Grid item>
          <Typography 
            variant="body2" 
            style={{ 
                color: '#ffffff', 
                marginTop: '5vh', 
                fontSize: '0.85rem'  
            }}
            >
            Maintenance in progress. Server up by  <br/><span style={{ color: '#ff6666', fontWeight: 'bold', fontSize: '0.8rem' }}>
                {formattedTime}
            </span>.
            </Typography>

            <Typography variant="body2" style={{ color: '#ffffff', marginTop: '0.5vh' }}>
              Thank you for your cooperation!
            </Typography>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}
