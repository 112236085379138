import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/Auth/Login";
import LoginMobile from "./pages/Auth/loginmobile";
import LoginWebgl from "./pages/Auth/loginwebgl";
import CustomLogin from "./pages/CustomLogin";
import "./styles/global.css";
import { useEffect } from "react";
import Callback from './callback/Callback';
import CallbackMobile from './callback/CallbackMobile';
import CallbackWebGl from './callback/CallbackWebGL';
import ConfirmationPage from "./pages/ConfirmationPage";
import VerificationFailedPage from "./pages/VerificationFailedPage";
import CheckStatusPage from "./pages/CheckStatusPage";
import ProcessPlayer from "./pages/ProcessPlayer";
import { updateFavicon } from "./utils/UpdateFavicon";
import EmailNotFound from "./pages/EmailNotFound";
import NotFoundPage from "./pages/NotFoundPage";
import ErrorPage from "./pages/ErrorPage";
import DuplicateAccountsPage from "./pages/DuplicateAccountsPage";
import NamePage from "./pages/NamePage";
import VerificationIdentityPage from "./pages/VerificationIdentityPage";
import VerificationIncompletePage from "./pages/VerificationIncompletePage";
import ContactSupport from "./pages/Auth/ContactSupport";
import VerificationComplete from "./pages/Auth/VerificationComplete";

const useFaviconAndTitle = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname.startsWith('/login')) {
      updateFavicon('/favicon.ico');
      document.title = 'Sign in/up | Hijack Poker';
    } else if (
      location.pathname.startsWith('/tch/login') ||
      location.pathname === '/tch/confirmed' ||
      location.pathname === '/tch/failed' ||
      location.pathname === '/tch/checking' ||
      location.pathname === '/tch/process'
    ) {
      updateFavicon('/favicon-tch.ico');
      document.title = 'Registration | Texas Card House';
    } else if (location.pathname.startsWith('/') || location.pathname === '*') {
      updateFavicon('/favicon.ico');
      document.title = 'Sign in/up | Hijack Poker';
    }
  }, [location]);
};


const App = () => {

  return (
    <div className="app">
      <Router>
        {/* <NavBar /> */}
        <FaviconAndTitleHandler />
        <Routes>
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
          <Route path="/login/:projectId" element={<Login />} />
          <Route path="/tch/login/:projectId" element={<CustomLogin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/loginmobile" element={<LoginMobile />} /> 
          <Route path="/loginwebgl" element={<LoginWebgl />} /> 
          <Route path="/authorization-code/callback" element={<Callback />} />
          <Route path="/authorization-code/callbackmobile" element={<CallbackMobile />} />
          <Route path="/authorization-code/callbackwebgl" element={<CallbackWebGl />} />
          <Route path="/contact-support" element={<ContactSupport />} />
          <Route path="/verification-complete" element={<VerificationComplete />} />

          <Route path="/tch/confirmed" element={<ConfirmationPage />} />
          <Route path="/tch/failed" element={<VerificationFailedPage />} />
          <Route path="/tch/checking" element={<CheckStatusPage />} />
          <Route path="/tch/process" element={<ProcessPlayer />} />
          <Route path="/tch/notfound" element={<EmailNotFound />} />
          <Route path="/tch/error" element={<ErrorPage />} />
          <Route path="/tch/error-duplicates" element={<DuplicateAccountsPage />} />
          <Route path="/tch/verifyidentity" element={<VerificationIdentityPage />} />
          <Route path="/tch/name" element={<NamePage />} />
          <Route path="/tch/incomplete" element={< VerificationIncompletePage/>} />
        </Routes>
      </Router>
    </div>
  );
}

const FaviconAndTitleHandler = () => {
  useFaviconAndTitle();
  return null;
};


export default App;
