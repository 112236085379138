import React, { useEffect, useState } from 'react';
import tchLogo from '../assets/tch-logo-v.png';
import { Container, Grid , Button, Typography} from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";

const VerificationIdentityPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const forceVerification = urlParams.get("force_verification") === "true";

  const location = useLocation();
  const navigate = useNavigate();

  const {
    userid,
    email,
    phone,
    playerNumber,
    identificationId,
    idVerified

  } = location.state || {};

  /**
   * If user selects Verify Online, it should proceed to the current process on identifying through Jumio
   * Redirects to Jumio steps
   */
  const handleOnline = () => {
    navigate(forceVerification ? "/tch/process?force_verification=true" : "/tch/process", {
      state: {
        userid: userid,
        email: email,
        idVerified: idVerified
      },
    });
  };

  /**
   * If user selects Verify In-Person, it redirects to a screen asking for First Name and Last Name
   * It will be then updated on both Descope and PokerAtlas
   * 
   * Redirects to In-Person steps
   */
  const handleTch = () => {
    navigate("/tch/name", {
      state: {
        userid: userid,
        email: email,
        phone: phone,
        identification_id: identificationId
      },
    });
  };

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
                <div
                  style={{
                    padding: "2rem 2rem",
                    backgroundColor: "#1e1e1e",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  <img style={{ width: '300px', marginBottom: '2rem' }} src={tchLogo} alt="TCH Logo" />  <br/> <br/>

                  <Typography variant='h5' marginBottom={3}>How would you like to verify your identity?</Typography>
                  <Typography variant='body1' marginBottom={3}>Verify in-person if you're at the club, or verify online from home—it’s up to you!</Typography>

                  <Grid container spacing={2} sx={{ marginBottom: '1rem'}}>
                    <Grid item xs>
                      <Button variant="contained" sx={{ backgroundColor: '#e98c26', color: 'white', width: '100%', "&:hover": {backgroundColor: '#94540f' } }} size="large" onClick={handleTch}>In-Person</Button>
                    </Grid>
                    <Grid item xs>
                      <Button variant="contained" sx={{ backgroundColor: '#e98c26', color: 'white', width: '100%', "&:hover": {backgroundColor: '#94540f' }}} size="large" onClick={handleOnline}>Online</Button> 
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default VerificationIdentityPage;