import { SignJWT } from 'jose';

export function getTokenPayload() {
  const date = Math.floor(Date.now() / 1000); // Use UTC time for the token
  return {
    instance: 'clubs',
    timestamp: date,
    iat: date
  };
}

export function getHeadersWithToken(token) {
  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`
  }
}

// Generate a JWT using `jose`
export async function generateToken(payload, secret, expiresIn) {
  const encoder = new TextEncoder(); // For encoding the secret as a Uint8Array
  const jwt = await new SignJWT({
    ...payload, // Include existing payload properties
  })
    .setProtectedHeader({ alg: 'HS256' })
    .setExpirationTime(expiresIn)
    .sign(encoder.encode(secret));
  return jwt;
}