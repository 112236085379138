import axios from 'axios';
import { getTokenPayload, getHeadersWithToken, generateToken } from "./functions";

const checkMaintenance = async () => {
  try {

    const payload = getTokenPayload();
    const token = await generateToken(payload, process.env.REACT_APP_DESCOPE_PROJECT_ID, '1h');
    const headers = getHeadersWithToken(token);

    const maintenanceResponse = await axios.post(
        `https://backoffice.${process.env.REACT_APP_DOMAIN}/api/maintenance-schedule/get-current-maintenance-schedule`,
        {}, 
        { headers } 
    );

    const maintenanceData = maintenanceResponse.data;
    
    if (maintenanceResponse.status === 200 && maintenanceData?.data?.length > 0) {
      const displayMessage = maintenanceData.data[0]?.display_message;

      return {
        status: maintenanceData.data[0]?.status,
        message: displayMessage, 
        title: maintenanceData.data[0]?.title,
        end_datetime : maintenanceData.data[0]?.end_datetime
      };
    }
    
    return {
      status: "ok",
      message: "No maintenance currently scheduled.",
      title: "",
      end_datetime: ""
    };
  } catch (error) {
    return {
      status: "error",
      message: error,
      title: "",
      end_datetime: ""
    };
  }
};

export default checkMaintenance;