import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DescopeLogin from "../../Descope/DescopeLogin"
import { Container, Grid } from '@mui/material';
import checkAccountStatus from '../../helpers/checkAccountStatus';
import { processJumioService } from '../../helpers/IdenfyHelper'; 
import checkMaintenance from '../../helpers/checkMaintenance';
import Maintenance from '../maintenance/maintenance';
import { ACCOUNT_STATUS } from '../../constants/constants';

export default function LoginMobile() {
  const navigate = useNavigate();

  const [maintenanceMessage, setMaintenanceMessage] = useState("");
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isPreMaintenance, setIsPreMaintenance] = useState(false); 
  const [title, settitle] = useState("");
  const [end_datetime, setEnddatetime] = useState("");

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      const maintenanceStatus = await checkMaintenance();
      if (maintenanceStatus.status === 'Active') {
        const formattedTime = maintenanceStatus.end_datetime 
        ? getLocalTime(maintenanceStatus.end_datetime) 
        : "N/A";
        setMaintenanceMessage(`Maintenance in progress. Server up by ${formattedTime}. Kindly exit after finishing your game. Thank you for your cooperation!`);
        settitle(maintenanceStatus.title)
        setEnddatetime(maintenanceStatus.end_datetime);
        setIsMaintenance(false);
        setIsPreMaintenance(true); 
      } else if (maintenanceStatus.status === 'PreMaintenance') {
        setMaintenanceMessage(maintenanceStatus.message);
        settitle(maintenanceStatus.title)
        setEnddatetime(maintenanceStatus.end_datetime);
        setIsMaintenance(false);
        setIsPreMaintenance(true); 
      }
      else if (maintenanceStatus.status === 'gameClose')
      {
        setMaintenanceMessage(maintenanceStatus.message);
        settitle(maintenanceStatus.title)
        setEnddatetime(maintenanceStatus.end_datetime);
        setIsMaintenance(true);
        setIsPreMaintenance(false); 
      }
      else {
        settitle("")
        setMaintenanceMessage("");
        setEnddatetime("");
        setIsMaintenance(false);
        setIsPreMaintenance(false); 
      }
    };

    fetchMaintenanceStatus();
    const interval = setInterval(fetchMaintenanceStatus, 60000); 
    return () => clearInterval(interval); 
  }, []);

  function getLocalTime(timestamp) {
    const date = new Date(timestamp * 1000);
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    const formattedTime = `${date.getHours() % 12 || 12}:${String(date.getMinutes()).padStart(2, '0')} ${ampm}`;
    return `${formattedDate} ${formattedTime}`;
  }

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const handleSuccess = async (event) => {

    const details = event.detail;
    const { sessionJwt, refreshJwt } = details;
    const userid = event.detail.user.userId;
    const email = event.detail.user.email;
    const login_type = 'loginmobile'

    const tokenCookie = JSON.stringify({
      access_token: sessionJwt,
      id_token: sessionJwt,
      refresh_token: refreshJwt,
    });

    const statusResult = await checkAccountStatus(email);
      if (statusResult.status === ACCOUNT_STATUS.OK) {
        const encodedTokenCookie = createEncodedTokenCookie(tokenCookie);
        console.log(encodedTokenCookie);
        window.location.href = `hijackpoker://account?refresh_token=${refreshJwt}`;
        return;
      }

      if(statusResult.status === ACCOUNT_STATUS.UNVERIFIED)
      {
        await processJumioService(userid, email,login_type);
        return;
      }
    
      if(statusResult.status === ACCOUNT_STATUS.DUPLICATE || statusResult.status === ACCOUNT_STATUS.WARNING)
      {
          navigate(`/contact-support?type=${login_type}`, {
            state: {
              message: statusResult.message
            }
          });
    
          return;
      }

      if(statusResult.status === ACCOUNT_STATUS.DENIED)
        {
          navigate(`/contact-support?type=${login_type}`, {
            state: {
              message: statusResult.message
            }
          });
    
          return;
        }

    const encodedTokenCookie = createEncodedTokenCookie(tokenCookie);
    console.log(encodedTokenCookie);
    window.location.href = `hijackpoker://account?refresh_token=${refreshJwt}`;
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
   {isPreMaintenance && (
        <marquee style={{ color: 'white', width: '100%', display: 'block' }}>
          {maintenanceMessage}
        </marquee>
      )}
    <div className='page' style={{ textAlign: 'center' }}>
        <Container maxWidth="sm">
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              {isMaintenance ? (
                            <Maintenance title={title} timestamp={end_datetime}/>
                          ) : (
                            <div className='descope-form' data-testid="descope-component">
                              <DescopeLogin onSuccess={handleSuccess} onError={() => console.log('Could not log in')} />
                            </div>
                          )}
            </Grid>
          </Grid>
        </Container>
      </div>
      </div>
  );
}

function createEncodedTokenCookie(stringToken) {
  let cleanedString = stringToken.replace(/ /g, "%20")
                                .replace(/"/g, "%22")
                                .replace(/,/g, "%2C");
  return cleanedString;
}
