import React, { useEffect, useRef, useState } from 'react';
import tchLogo from '../assets/tch-logo-v.png';
import Box from '@mui/material/Box';
import { Button, Container, Grid, Skeleton, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const VerificationFailedPage = () => {
  const location = useLocation();
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  // const [qrCodeUrlJson, setQrCodeUrlJson] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasFetchedQrCode = useRef(false);

  const { state } = location;
  const { guid, firstName, lastName, email, playerNumber, idReason, idVerified } = state || {};

  const getIdReason = (idVerified, idReason) => {
    if (!idVerified || (idVerified !== "APPROVED" && idVerified !== "SUSPECTED")) {
      return "Your ID verification needs further review.";
    }
    return idReason === "Verified through Jumio" ? "Your ID has been verified using Jumio." : idReason;
  };

  const sendEmail = async (firstName, lastName, email, playerNumber, qrCodeJson) => {
    try {
      console.log('sending email: ', qrCodeJson);
      const emailResponse = await axios.post(
        `${process.env.REACT_APP_BACKOFFICE_URL}/api/players/send-tch-registration-email`, 
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          playerNumber: playerNumber,
          qrCodeUrl: qrCodeJson
        }
      );
      if (emailResponse.status !== 200) {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      console.error('Email send error:', error);
    }
  };

  const generateQrCode = async (playerNumber, responseType) => {
    const headers = {
      "x-rapidapi-key": process.env.REACT_APP_X_RAPIDAPI_KEY,
      "Content-Type": "application/json"
    };

    const raw = JSON.stringify({
      data: `%P${playerNumber}?`,
      config: {
        body: "square",
        bodyColor: "#000000",
        bgColor: "#FFFFFF",
        eye1Color: "#000000",
        eye2Color: "#000000",
        eye3Color: "#000000",
        eyeBall1Color: "#000000",
        eyeBall2Color: "#000000",
        eyeBall3Color: "#000000",
        gradientColor1: "#000000",
        gradientColor2: "#000000",
        gradientType: "radial",
        gradientOnEyes: false,
        logo: "https://44736585.fs1.hubspotusercontent-na1.net/hubfs/44736585/tch_logo_qrcode.jpg",
        logoMode: "clean"
      },
      size: 200,
      download: true,
      file: "png"
    });

    try {
      const response = await fetch("https://qrcode-monkey.p.rapidapi.com/qr/custom", {
        method: "POST",
        headers: headers,
        body: raw,
        redirect: "follow"
      });

      if (responseType === 'blob') {
        const textResponse = await response.text();
        const jsonResponse = JSON.parse(textResponse);
        const imageUrl = `https:${jsonResponse.imageUrl}`;
        setQrCodeUrl(imageUrl);
      } else if (responseType === 'json') {
        const jsonResponse = await response.json();
        const imageUrl = jsonResponse?.imageUrl;
        console.log('email QR Code url: ', imageUrl)
        // setQrCodeUrlJson(imageUrl);
        return imageUrl;
      }
    } catch (error) {
      console.error('QR code fetch error:', error);
      return null;
    }
  };

  const fetchAndSendQrCodes = async () => {
    if (hasFetchedQrCode.current) return;
    hasFetchedQrCode.current = true;

    setLoading(true);

    try {
      // Generate QR code for display
      await generateQrCode(playerNumber, 'blob');

      // Generate QR code in JSON format for email
      const qrCodeJson = await generateQrCode(playerNumber, 'json');

      // Send email with QR code
      if (qrCodeJson) {
        await sendEmail(firstName, lastName, email, playerNumber, qrCodeJson);
      }
    } catch (error) {
      console.error('Error generating or sending QR codes:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (playerNumber) {
      fetchAndSendQrCodes();
    }
  }, [playerNumber]);

  return (
    <>
      <div className="page">
        <Container maxWidth="sm" sx={{ paddingY: "16px" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <div className='descope-form' data-testid="descope-component">
              <div
                style={{
                  padding: "2rem 2rem",
                  backgroundColor: "#1e1e1e",
                  color: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                  <img style={{ width: '300px', marginBottom: '1rem' }} src={tchLogo} alt="TCH Logo" />
                  <Typography mb={2}>
                    {email ? (
                      <>ID verification failed. Click 'Try Again' or visit the front desk for manual verification.</>
                    ) : (
                      <>
                        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                          <Skeleton sx={{ backgroundColor: "#434343" }} width='90%' />
                          <Skeleton sx={{ backgroundColor: "#434343" }} width='100%' />
                          <Skeleton sx={{ backgroundColor: "#434343" }} width='30%' />
                        </Box>
                      </>
                    )}
                  </Typography>
                  {qrCodeUrl ? (
                      <img
                        src={qrCodeUrl}
                        alt="QR Code"
                        style={{
                          width: "180px",
                          height: "180px",
                          backgroundColor: "white",
                          borderRadius: "10px",
                        }}
                      />
                    ) : (
                      <Box sx={{ display: "flex", justifyContent: 'center' }}>
                        {loading && (
                          <Skeleton 
                            sx={{ 
                              backgroundColor: "#434343", 
                              width: 180, 
                              height: 180,
                              borderRadius: '10px'
                            }} 
                            variant="rectangular"
                          />
                        )}
                      </Box>
                    )}
                  <Typography variant="subtitle2" mt={2}>
                    {getIdReason(idVerified, idReason)}
                  </Typography>
                  <Typography variant="subtitle2" mb={1}>
                    Reason
                  </Typography>
                  <Typography variant="h6" mt={2}>
                    {playerNumber ? (<>{playerNumber}</>) : (
                      <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Skeleton sx={{ backgroundColor: "#434343" }} width='60%' />
                      </Box>
                    )}
                  </Typography>
                  <Typography variant="subtitle2" mb={1}>
                    Player Number
                  </Typography>
                  <Typography variant="h6">
                    {firstName && lastName ? (<>{firstName} {lastName}</>) : (
                      <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Skeleton sx={{ backgroundColor: "#434343" }} width='80%' />
                      </Box>
                    )}
                  </Typography>
                  <Typography variant="subtitle2" mb={1}>
                    Player Name
                  </Typography>
                  <Typography variant="h6">
                    {email ? (<>{email}</>) : (
                      <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Skeleton sx={{ backgroundColor: "#434343" }} width='80%' />
                      </Box>
                    )}
                  </Typography>
                  <Typography variant="subtitle2" mb={4}>
                    Player Email Address
                  </Typography>
                  <ThemeProvider theme={DefaultBtn}>
                    <Button size='small' sx={{marginBottom: '1rem'}} onClick={() => handleAPICall(guid, email)}>
                      Try Again
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

const buildUrlWithParams = (baseUrl, params) => {
  const queryString = new URLSearchParams(params).toString();
  return `${baseUrl}?${queryString}`;
};

const handleAPICall = async (guid, loginid) => {
  const baseUrl = `https://auth.${process.env.REACT_APP_DOMAIN}/tch/checking`;
  const params = {
    email: encodeURIComponent(loginid),
  };

  const successurl = buildUrlWithParams(baseUrl, params);
  const errorurl = buildUrlWithParams(baseUrl, params);
  const unverifiedurl = buildUrlWithParams(baseUrl, params);

  let formData = new FormData(); 
  formData.append('GUID', guid);
  formData.append('loginId', loginid); 
  formData.append('phone', ''); 
  formData.append('verifiedEmail', ''); 
  formData.append('verifiedPhone', '');
  formData.append('successurl', successurl);
  formData.append('errorurl', errorurl);
  formData.append('unverifiedurl', unverifiedurl);

  try {
    const response = await fetch(
      `https://backoffice.${process.env.REACT_APP_DOMAIN}/api/identity/pokeratlas/initiate`,
      {
        method: "POST",
        headers: {
          Accept: "*/*"
        },
        body: formData,
      }
    );

    const data = await response.json();
    return data.web?.href || null;
  } catch (error) {
    console.error('Fetch error:', error);
    return null;
  }
}

const DefaultBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#D08E34',
          '&.active': {
            backgroundColor: '#D08E34',
            '&:hover': {
              backgroundColor: '#BE7E27'
            }
          },
          '&:hover': {
            color: 'white',
            backgroundColor: '#BE7E27'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.75rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

export default VerificationFailedPage;