export const ACCOUNT_STATUS = {
  WARNING: 'WARNING',
  OK: 'OK',
  UNVERIFIED: 'UNVERIFIED',
  DENIED: 'DENIED',
  DUPLICATE: 'DUPLICATE'
}

export const ID_VERIFIED = {
  APPROVED: 'APPROVED',
  SUSPECTED: 'SUSPECTED',
  DENIED: 'DENIED',
  WARNING: 'WARNING'
}