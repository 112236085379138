import { Descope } from '@descope/react-sdk';

export default function DescopeLogin({ onSuccess, onError, disabled }) {
  return (
    <div style={{ position: 'relative' }}>
      {disabled && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0)', 
            zIndex: 1, 
            pointerEvents: 'auto', 
          }}
        />
      )}
      
      <Descope
        flowId="sign-up-or-in"
        onSuccess={onSuccess}
        onError={onError}
        theme="light"
      />
    </div>
  );
}
